import React, { useState, useEffect } from "react";
import { setCookie } from "../Utils/Cookies.js";
import ArbButton from "./arbButton";
import Modal from "react-bootstrap/Modal";
import { Fetcher } from "../Utils/AxiosWrapper";
import { checkRequired, removeOutline } from "../Utils/helpers";
import Checkbox from "./checkbox.tsx";
import { GENERIC_ERROR_MESSAGE_HTML } from "../Lib/globals";
import CloseModalButton from "./closeModalButton";

export default function Register({ email, password, code, firstName, lastName, registerwithcode }) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [disable, setDisabled] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [checked, setChecked] = useState(false);
  const [show, setShow] = useState(false);
  const [termsOfService, setTermsOfService] = useState("");
  const [myFirstName, setMyFirstName] = useState(firstName);
  const [myLastName, setMyLastName] = useState(lastName);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const clickHandler = function (e) {
    e.preventDefault();
    setDisabled(true);
    checkFields();
  };

  useEffect(() => {
    Fetcher("Get", "/TermsOfService/portal")
      .then((response) => {
        setTermsOfService(response.data.termsOfServiceHtml);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const registrationCompleted = function (message) {
    setConfirmationModalMessage(message);
    setShowConfirmModal(true);
  };

  const rerouteToLogin = function () {
    if (!code || code === "NA" || registerwithcode) {
      window.location = "/emailverification?email=" + encodeURIComponent(email);
    } else {
      window.location.href = "/";
    }
  };

  const handleShowingErrorForRegisterAPICall = function (error) {
    if (error && error.data && error.data.message) {
      if (error.data.errorCode === "ACCOUNTALREADYREGISTERED") {
        setMessageError(
          `${error.data.message} Please <a href="/login" class="loginLink">click here</a> to navigate back to the login page.`
        );
      } else {
        setMessageError(error.data.message);
      }
      return;
    }

    setMessageError(GENERIC_ERROR_MESSAGE_HTML);
  };

  const register = function () {
    const requestOptions = {
      email: email.trim(),
      firstName: myFirstName,
      lastName: myLastName,
      password: password,
      confirmPassword: password,
      hasAcceptedTermsOfService: document.getElementById("checked").value === "true",
    };
    if (!code || code === "NA") {
      Fetcher("POST", "/Auth/register", requestOptions)
        .then(({ data }) => {
          setMessageError("");
          if ("token" in data && data.token) {
            setCookie("token", data.token, 7);
          }
          registrationCompleted(
            `Thank you for registering. Before you can log in, you need to verify your email address. An email has been sent to ${email} with a verification code. Click OK and enter that code on the next screen to log in.`
          );
        })
        .catch((error) => {
          pause = false;
          setDisabled(false);
          handleShowingErrorForRegisterAPICall(error);
        });
    } else {
      Fetcher(registerwithcode ? "POST" : "PATCH", "/Auth/register?code=" + encodeURIComponent(code), requestOptions)
        .then((response) => {
          setMessageError("");
          setCookie("token", response.data.token, 7);
          if (registerwithcode) {
            return registrationCompleted(
              `Thank you for registering. Before you can log in, you need to verify your email address. An email has been sent to ${email} with a verification code. Click OK and enter that code on the next screen to log in.`
            );
          }
          return registrationCompleted("Registration completed successfully, Click ok to be logged in.");
        })
        .catch((error) => {
          pause = false;
          setDisabled(false);
          handleShowingErrorForRegisterAPICall(error);
        });
    }
  };

  const checkFields = function () {
    if (!pause && !checkRequired("registerForm")) {
      pause = true;
      register();
    } else if (checkRequired("registerForm")) {
      setDisabled(false);
    }
  };

  return (
    <div>
      <input type="text" style={{ display: `none` }} id="checked" value={checked} readOnly />
      <form id="registerForm">
        <label htmlFor="firstName" className="text-input__label">
          First Name:
        </label>
        <input
          className="text-input"
          tabIndex={1}
          type="text"
          onChange={(e) => {
            removeOutline(e.target);
            setMyFirstName(e.target.value);
          }}
          value={myFirstName}
          id="firstName"
          maxLength={"256"}
          required
        />
        <label htmlFor="lastName" className="text-input__label">
          Last Name:
        </label>
        <input
          tabIndex={2}
          type="text"
          onChange={(e) => {
            removeOutline(e.target);
            setMyLastName(e.target.value);
          }}
          value={myLastName}
          id="lastName"
          maxLength={"256"}
          className="text-input"
          required
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div style={{ height: "60px" }}>
            <input
              id="termsAccept"
              style={{ opacity: "0" }}
              type="checkbox"
              className="checkbox"
              checked={checked}
              onChange={() => {
                setChecked(!checked);
              }}
            />
            <div
              ariaLabel="Accept Terms of Service Toggle"
              tabIndex={5}
              className="checkboxHolder"
              style={{ height: "15px", width: "15px", margin: "0px 10px 1px 0px" }}
              onKeyUp={(e) => {
                (e.key === "Enter" || e.key === " ") && setChecked(!checked);
              }}
            >
              <Checkbox
                ariaLabel="Accept Terms of Service Toggle"
                id="acceptTermsOfServiceCheckbox"
                checked={checked}
                onChange={(value) => {
                  setChecked(value);
                }}
              />
            </div>
            <label
              className="TermsOfService"
              style={{
                marginTop: `-19px`,
                marginLeft: `18px`,
                fontWeight: `400`,
                verticalAlign: `27px`,
                fontSize: `14px`,
              }}
              htmlFor="termsAccept"
            >
              I accept
              <span
                tabIndex={6}
                className="loginLink"
                onClick={handleShow}
                onKeyUp={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleShow();
                  }
                }}
                style={{ cursor: "pointer", fontSize: `14px` }}
              >
                {" Terms of Service"}
              </span>
            </label>
          </div>
          <span style={{ float: `center`, marginTop: `0px`, marginBottom: "10px" }}>
            {disable ? (
              <ArbButton tabIndex={7} size="small">
                Submit
              </ArbButton>
            ) : (
              <ArbButton tabIndex={7} type="submit" size="small" onClick={clickHandler}>
                Submit
              </ArbButton>
            )}
          </span>
        </div>
      </form>
      {messageError && (
        <div className="highContrastErrorMessage" role="alert" dangerouslySetInnerHTML={{ __html: messageError }} />
      )}
      <Modal
        className="termsModal"
        style={{ border: `none` }}
        show={show}
        onHide={handleClose}
        centered
        scrollable={true}
      >
        <Modal.Header
          style={{
            backgroundImage: "url(/Images/termsHeader.png)",
            height: `40px`,
            color: `white`,
            border: `none`,
          }}
          closeButton={false}
        >
          <Modal.Title className="loginTermsOfServiceHeader">Terms of Service</Modal.Title>
          <CloseModalButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: `#191919`,
            border: `none`,
            maxHeight: `80vh`,
            overflowY: `auto`,
          }}
          className="loginModalBody"
        >
          <div dangerouslySetInnerHTML={{ __html: termsOfService }} />
        </Modal.Body>
      </Modal>
      <Modal
        className="termsModal"
        size="md"
        style={{ border: `none`, zIndex: "999999" }}
        show={showConfirmModal}
        onHide={rerouteToLogin}
        centered
        scrollable={true}
      >
        <Modal.Header
          className="bold"
          style={{
            backgroundImage: `url(/Images/termsHeader.png)`,
            backgroundSize: `cover`,
            height: `45px`,
            color: `white`,
            border: `none`,
          }}
          closeButton={false}
        >
          <Modal.Title className="loginTermsOfServiceHeader" style={{ fontSize: `20px` }}>
            Registration Confirmation
          </Modal.Title>
          <CloseModalButton onClick={rerouteToLogin} />
        </Modal.Header>
        <Modal.Body style={{ padding: `45px`, color: `#888888` }} className="loginModalBody">
          <p style={{ fontSize: "14px" }}>{confirmationModalMessage}</p>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <ArbButton size="small" onClick={rerouteToLogin}>
              Ok
            </ArbButton>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

var pause = false;
