import React, { useState } from "react";
import ArbButton from "../Components/arbButton";
import Modal from "react-bootstrap/Modal";
import { checkRequired, removeOutline } from "../Utils/helpers";
import { Fetcher } from "../Utils/AxiosWrapper";
import CloseModalButton from "Components/closeModalButton";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [disable, setDisabled] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [messageError, setMessageError] = useState("");

  const passwordReset = function () {
    const requestEmail = encodeURIComponent(email);
    Fetcher("GET", "/Auth/password/forgot?email=" + requestEmail)
      .then(() => {
        setShowConfirmModal(true);
      })
      .catch((error) => {
        pause = false;
        setDisabled(false);
        error && error.data && error.data.message ? setMessageError(error.data.message) : setMessageError("");
      });
  };

  const checkFields = function () {
    if (!pause && !checkRequired("passwordForm")) {
      pause = true;
      passwordReset();
    } else if (checkRequired("passwordForm")) {
      setDisabled(false);
    }
  };

  const clickHandler = async function (e) {
    e.preventDefault();
    setDisabled(true);
    checkFields();
  };

  return (
    <div>
      <form id="passwordForm" onSubmit={clickHandler}>
        <div style={{ fontSize: `12px`, lineHeight: `15px` }}>
          <p className="paragraph">
            To reset your password, please enter your email address below, then click submit. We will send you an email
            with instructions on how to reset your password.
          </p>
        </div>
        <br />
        <label htmlFor="email" className="text-input__label">
          Email:
        </label>
        <input
          maxLength={254}
          className="text-input"
          value={email}
          type="email"
          id="email"
          onChange={(e) => {
            setEmail(e.target.value);
            removeOutline(e.target);
            setMessageError("");
          }}
          required
        />
        <br />
        <br />
        <div style={{ float: `left` }}>
          <ArbButton
            size="small"
            onClick={() => {
              window.location.href = "/login";
            }}
          >
            Back
          </ArbButton>
        </div>
        <div style={{ float: `right`, marginBottom: "10px" }}>
          {disable ? (
            <ArbButton size="small">Submit</ArbButton>
          ) : (
            <ArbButton size="small" type="submit" onClick={() => {}}>
              Submit
            </ArbButton>
          )}
        </div>
      </form>

      {messageError && (
        <div className="highContrastErrorMessage" role="alert" dangerouslySetInnerHTML={{ __html: messageError }} />
      )}
      <Modal
        className="termsModal"
        size="md"
        style={{ border: `none`, zIndex: "999999" }}
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(!showConfirmModal)}
        centered
        scrollable={true}
      >
        <Modal.Header
          className="bold"
          style={{
            backgroundImage: `url(/Images/termsHeader.png)`,
            backgroundSize: `cover`,
            height: `45px`,
            color: `white`,
            border: `none`,
          }}
          closeButton={false}
        >
          <Modal.Title className="loginTermsOfServiceHeader" style={{ fontSize: `20px` }}>
            Email Sent
          </Modal.Title>
          <CloseModalButton onClick={() => setShowConfirmModal(!showConfirmModal)} />
        </Modal.Header>
        <Modal.Body style={{ padding: `45px`, color: `#888888` }} className="loginModalBody">
          <p className="paragraph">Please check your email for instructions on how to reset your password.</p>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <ArbButton size="small" onClick={() => setShowConfirmModal(!showConfirmModal)}>
              Ok
            </ArbButton>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

var pause = false;
