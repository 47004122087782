import React from "react";
import { NavLink as Link } from "react-router-dom";

type IType = "button" | "submit";
type ISize = "large" | "text" | "text-uppercase" | "text-capitalize" | "small" | "sm";

interface IProps {
  children: any;
  onClick: any;
  type: IType;
  ariaLabel?: string;
  tabIndex?: number;
  fileName?: string;
  right?: boolean;
  left?: boolean;
  to?: string;
  long?: boolean;
  size?: ISize;
  disabled?: boolean;
  isProcessing?: boolean;
  isRouterLink?: boolean;
  download?: boolean;
  borderless?: boolean;
  className?: string;
  tooltip?: string;
  data_cy?: string;
  textColor?: string;
}

export default function ArbButton({
  children,
  onClick,
  type = "button",
  ariaLabel = "",
  size,
  to,
  long,
  right,
  left,
  disabled = false,
  fileName,
  isProcessing = false,
  isRouterLink = false,
  className = "",
  tooltip = "",
  tabIndex = 0,
  download = false,
  borderless = false,
  data_cy = "",
  textColor,
}: IProps) {
  if (download) {
    return (
      <button
        onClick={onClick}
        //@ts-ignore Is target=_blank a property on a button
        target="_blank"
        rel="noreferrer"
        className="smallBtn noBorder"
        aria-label={ariaLabel}
        data-cy={data_cy}
        style={{
          fontSize: "14px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {children ? children : "Download"}
          <img
            alt=""
            style={{ margin: `3px 0px 3px 10px`, height: `14px`, width: "14px" }}
            src={window.location.origin + "/Images/Downloadv2.png"}
          />
        </div>
      </button>
    );
  }

  if (isRouterLink && to) {
    return (
      <Link
        aria-label={ariaLabel}
        to={to}
        data-cy={data_cy}
        className={className + " " + size === "large" ? "largeBtn" : long ? "smallLongBtn" : "smallBtn"}
        style={{
          float: right ? "right" : left ? "left" : "none",
          textAlign: "center",
          textDecoration: "none",
        }}
      >
        {children}
      </Link>
    );
  }

  if (onClick) {
    return (
      <div className="buttonAndSpinnerContainer">
        <div style={{ position: "relative", margin: "2px", top: borderless ? "2px" : "0px" }}>
          <input
            aria-label={ariaLabel}
            tabIndex={tabIndex}
            maxLength={256}
            type={type}
            disabled={disabled || isProcessing}
            data-cy={data_cy}
            className={
              className +
              (className ? " " : "") +
              (size === "text"
                ? "btn-text"
                : size === "text-uppercase"
                ? "btn-text-uppercase"
                : size === "text-capitalize"
                ? "btn-text-capitalize"
                : size === "large"
                ? "largeBtn"
                : long
                ? "smallLongBtn"
                : "smallBtn") +
              (tooltip !== "" ? " tooltip" : "") +
              (borderless ? " focusable" : "")
            }
            style={{
              textAlign: "center",
              float: right ? "right" : left ? "left" : "none",
              border: borderless ? "none" : isProcessing ? "2px solid #444" : "",
              color: textColor ? textColor : isProcessing ? "transparent" : "",
              boxSizing: "border-box",
            }}
            onClick={onClick}
            value={children}
          />
          {isProcessing && <img alt="loading" src="/Images/LoadingPleaseWait.gif" className="buttonLoadingIconImage" />}
          {tooltip !== "" && <span className="tooltip-text">{tooltip}</span>}
        </div>
      </div>
    );
  }

  return (
    <a
      aria-label={ariaLabel}
      href={to}
      target="_blank"
      rel="noreferrer"
      data-cy={data_cy}
      className={className + " " + size === "large" ? "largeBtn" : long ? "smallLongBtn" : "smallBtn"}
      style={{
        boxSizing: "border-box",
        float: right ? "right" : left ? "left" : "none",
        textAlign: "center",
        height: "37px",
      }}
      download={fileName}
    >
      {children}
    </a>
  );
}
