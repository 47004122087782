import React from "react";
import Modal from "react-bootstrap/Modal";
import CloseModalButton from "./closeModalButton";

interface IProps {
  children: any;
  onClose: any;
  title: string;
  secondarytitle?: string;
  isVisible: boolean;
  size: "sm" | "md" | "lg" | "xl" | undefined;
  bclassName?: string;
  backgroundColor?: string;
  noClose?: boolean;
}

export default function ArbModal({
  children,
  onClose,
  title,
  secondarytitle = undefined,
  isVisible = false,
  size = "md",
  bclassName = "",
  backgroundColor = "#191919",
  noClose = false,
}: IProps) {
  return (
    <Modal
      className="modal"
      aria-labelledby="modalTitle1"
      show={isVisible}
      //@ts-ignore there is an md size, the bootstrap type system is wrong
      size={size}
      onHide={onClose}
      centered
      scrollable={true}
    >
      <Modal.Header
        className="bold modal__header"
        style={{
          backgroundImage: "url(/Images/termsHeader.png)",
          border: `none`,
        }}
        closeButton={false}
      >
        <Modal.Title className="modal__header-title" id="modalTitle1">
          {title}
        </Modal.Title>
        {secondarytitle ? <Modal.Title className="modal__header-title">Total: {secondarytitle}%</Modal.Title> : null}
        {!noClose && <CloseModalButton onClick={onClose} />}
      </Modal.Header>
      <Modal.Body className={"modal__body " + bclassName} style={{ backgroundColor: backgroundColor }}>
        {children}
      </Modal.Body>
    </Modal>
  );
}
