//eslint-disable file

function isValidEmail(email) {
  const emailRegularExpression = /^[^@]+@[^@]+\.[^@]+$/;
  return emailRegularExpression.test(email);
}

module.exports = {
  isValidEmail,
};
