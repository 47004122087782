import React from "react";
import ArbButton from "../Components/arbButton";
import { Helmet } from "react-helmet";

export default function Forbidden403() {
  return (
    <div className="error-container">
      <Helmet>
        <title>Forbidden - Arbinger Portal</title>
      </Helmet>
      <div className="error-content">
        <img className="error-content__logo" src="/Images/TransparentLogo.png" alt="Arbinger Logo" />
        <h1 className="error-content__header">403 Permission Denied</h1>
        <p className="error-content__text">
          You do not have permission to access the requested page or resource. Please make sure that you are logged into
          your Arbinger Account and that you have a proper license to view the material you have requested. If you need
          help, please contact your organization's administrator. Click the button below to login to your Arbinger
          account or to go to the Arbinger homepage.
        </p>

        <ArbButton to="/login">Arbinger Login</ArbButton>
        <span style={{ marginRight: "22px" }}></span>
        <ArbButton to="/">Arbinger Home</ArbButton>
      </div>
    </div>
  );
}
