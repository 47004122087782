import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

// Polyfill to allow promises.
import "es6-promise/auto";

// Allow Object.Assign, this was needed for lodash
import "./Polyfills/objectAssign";

// Polyfills to fix ZoomWrapper
import "./Polyfills/objectIncludes";
import "core-js/es/string";
import "airbnb-js-shims";

import React from "react";
import ReactDOM from "react-dom";
import "./Styles/CSS/main.css";
import "./Styles/CSS/MyFontsWebfontsKit.css";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
//Used for Toasts
import "bootstrap/dist/css/bootstrap.min.css";

import { CookiesProvider } from "react-cookie";
import { SWRConfig } from "swr";

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Router>
        <SWRConfig
          value={{
            refreshInterval: 15000,
          }}
        >
          <App />
        </SWRConfig>
      </Router>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
