import React from "react";
import { Route, Switch } from "react-router-dom";
import { AuthRoute } from "./Extensions/AuthRoute.js";
import Loadable from "react-loadable";
import Loading from "./Components/loading.jsx";
import ForgotPassword from "./Pages/forgotPassword.jsx";
import LoginLayout from "./layouts/loginLayout.jsx";
import Register from "./Pages/register.jsx";
import ResetPassword from "./Pages/resetPassword.jsx";
import RedirectExternal from "./Components/redirectExternal";
const SurveyRedirect = Loadable({
  loader: () => import("./Pages/surveyRedirect"),
  loading: Loading,
});
const Declined = Loadable({
  loader: () => import("./Pages/declined"),
  loading: Loading,
});
const Accepted = Loadable({
  loader: () => import("./Pages/accepted"),
  loading: Loading,
});
const Logout = Loadable({
  loader: () => import("./Pages/Logout.jsx"),
  loading: Loading,
});
const AuthLayout = Loadable({
  loader: () => import("./layouts/authLayout.jsx"),
  loading: Loading,
});
const Login = Loadable({
  loader: () => import("./Pages/login.jsx"),
  loading: Loading,
});
const EmailVerification = Loadable({
  loader: () => import("./Pages/emailVerification.jsx"),
  loading: Loading,
});

export default function App() {
  let path = window.location.pathname.split("/");
  let workbook = window.location.pathname.replace("workbook/", "");
  return (
    <Switch>
      <Route exact path="/login">
        <LoginLayout showRegister={true}>
          <Login />
        </LoginLayout>
      </Route>
      <Route exact path="/registercode">
        <LoginLayout>
          <Login registerwithcode />
        </LoginLayout>
      </Route>
      <Route exact path="/register">
        <LoginLayout>
          <Register />
        </LoginLayout>
      </Route>
      <Route exact path="/forgotpassword">
        <LoginLayout>
          <ForgotPassword />
        </LoginLayout>
      </Route>
      <Route exact path="/passwordreset">
        <LoginLayout>
          <ResetPassword />
        </LoginLayout>
      </Route>
      <Route exact path="/emailverification">
        <LoginLayout>
          <EmailVerification />
        </LoginLayout>
      </Route>
      <Route exact path="/logout">
        <LoginLayout>
          <Logout />
        </LoginLayout>
      </Route>
      <Route exact path="/surveyredirect">
        <SurveyRedirect />
      </Route>
      <Route path="/workbook">
        <RedirectExternal
          combined={
            path.length > 1 ? (path.length > 2 && path[1] === "workbook" ? path[2] : path[1]) : "outwardInclusion"
          }
          lastRoute={workbook}
        />
      </Route>
      <Route path="/declined">
        <Declined />
      </Route>
      <Route path="/accepted">
        <Accepted />
      </Route>
      <AuthRoute path="/*">
        <AuthLayout />
      </AuthRoute>
    </Switch>
  );
}
