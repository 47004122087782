/* eslint-disable eqeqeq */
export function setCookie(name, value, daysTillExpiration) {
  var currentDate = new Date();
  currentDate.setTime(currentDate.getTime() + daysTillExpiration * 24 * 60 * 60 * 1000);
  var expires = "expires=" + currentDate.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";

  if (name == "token") {
    document.cookie = "arbtoken=" + value + ";" + expires + ";path=/;domain=.arbinger.com";
  }
}

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
