import React from "react";
import PasswordReset from "../Components/passwordReset.jsx";
import { Helmet } from "react-helmet";

export default function Register() {
  return (
    <>
      <Helmet>
        <title>Register - Arbinger Portal</title>
      </Helmet>
      <PasswordReset register={true} />
    </>
  );
}
