// @ts-ignore
import CustomCheckbox from "react-custom-checkbox";
import * as Icon from "react-icons/fa";
import React from "react";

interface IProps {
  className?: string;
  id: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (arg0: boolean) => void;
  ariaLabel?: string;
}

export default function Checkbox({ className = "", id, checked, disabled = false, onChange, ariaLabel }: IProps) {
  return (
    <div aria-label={ariaLabel}>
      <CustomCheckbox
        tabIndex={0}
        className={className}
        id={id}
        checked={checked}
        disabled={disabled}
        borderColor="#8E8E8E"
        borderWidth={1}
        borderRadius={1}
        size={11}
        icon={
          <span style={{ marginLeft: `.5px`, marginTop: `-4px`, flex: 1 }}>
            <Icon.FaCheck id="checked" color="#dddddd" size={8} />
          </span>
        }
        onChange={onChange}
      />
    </div>
  );
}
