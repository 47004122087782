import React, { useState, useEffect } from "react";
import { GENERIC_ERROR_MESSAGE_HTML } from "../Lib/globals";
import ArbButton from "./arbButton";
import ArbModal from "./arbModal";
import Register from "./register";
import { Fetcher } from "../Utils/AxiosWrapper";
import Modal from "react-bootstrap/Modal";
import { checkRequired, removeOutline } from "../Utils/helpers";
import $ from "jquery";
import { isValidEmail } from "../Utils/RegularExpressions";
import CloseModalButton from "./closeModalButton";

export default function PasswordReset({ register }) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showErrorModal, setErrorModal] = useState(false);
  const [disable, setDisabled] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [registerwithcode] = useState(urlParams.get("coderegistration") ? true : false);
  const [showRerouteToLoginPopup, setShowRerouteToLoginPopup] = useState(false);

  useEffect(() => {
    if (urlParams.get("email") && urlParams.get("code") && register) {
      hasUserAlreadyRegistered();
    }
  }, []);

  const hasUserAlreadyRegistered = async function () {
    try {
      const { data } = await Fetcher(
        "GET",
        `auth/hasUserAlreadyRegistered?code=${urlParams.get("code").trim()}&email=${encodeURIComponent(
          urlParams.get("email")
        ).trim()}`
      );
      if (data.hasRegistered) {
        setShowRerouteToLoginPopup(true);
        //HANDLE THE SCENARIO WHERE THE USER HAS ALREADY REGISTERED
        return;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const clickHandler = async function (e) {
    e.preventDefault();
    setDisabled(true);
    checkFields();
  };

  const reroute = function () {
    if (displayModal) {
      setShowConfirmModal(true);
    } else {
      setTimeout(function () {
        setEmail($("#email").val().trim());
        if (responseData) {
          setFirstName(responseData.hasOwnProperty("firstName") ? responseData.firstName : "");
          setLastName(responseData.hasOwnProperty("lastName") ? responseData.lastName : "");
          setOrganizationName(responseData.hasOwnProperty("organizationName") ? responseData.organizationName : "");
        }
        setPassword($("#password").val());
      }, 1);
    }
  };

  const isEmailInputValid = function () {
    return isValidEmail(String($("#email").val().toLowerCase()));
  };

  const areEmailAndPasswordValid = function () {
    let tempErrorMessage = messageError;

    if (isEmailInputValid() === false) {
      pause = false;
      setDisabled(false);
      if (tempErrorMessage.indexOf("<p>The email provided is invalid.</p>") === -1) {
        tempErrorMessage = tempErrorMessage + "<p>The email provided is invalid.</p>";
      }
      setMessageError(tempErrorMessage);
      return false;
    } else {
      tempErrorMessage = tempErrorMessage.replace("<p>The email provided is invalid.</p>", "");
    }

    if ($("#password").val() !== $("#confirmPassword").val()) {
      pause = false;
      setDisabled(false);
      if (tempErrorMessage.indexOf("<p>Password and Confirm Password must match.</p>") === -1) {
        tempErrorMessage = tempErrorMessage + "<p>Password and Confirm Password must match.</p>";
      }
      setMessageError(tempErrorMessage);
      return false;
    } else {
      tempErrorMessage = tempErrorMessage.replace("<p>Password and Confirm Password must match.</p>", "");
    }

    if (!tempErrorMessage) {
      setMessageError("");
    } else {
      setMessageError(tempErrorMessage);
    }

    return true;
  };

  const validate = function () {
    if (!areEmailAndPasswordValid) {
      return;
    }
    if (register) {
      setErrorModal(false);
      const requestEmail = encodeURIComponent($("#email").val().trim());
      const requestPassword = encodeURIComponent($("#password").val());
      const code = urlParams.get("code") === "NA" ? "" : encodeURIComponent(urlParams.get("code").trim());
      let url = "/Auth/account/validate?email=" + requestEmail + "&password=" + requestPassword + "&code=" + code;
      if (!url.includes("undefined")) {
        Fetcher("GET", url)
          .then((response) => {
            responseData = response.data;
            reroute();
          })
          .catch((error) => {
            pause = false;
            setDisabled(false);
            if (error && error.data && error.data.errorCode === "USEREXISTS") {
              setMessageError("That email is already registered, return to the login page and login.");
              return;
            }
            if (error && error.data && error.data.errorCode === "USERDOESNOTEXIST" && !email) {
              if (registerwithcode) {
                reroute();
              } else {
                setErrorModal(true);
              }
            } else {
              error && error.data && error.data.message
                ? setMessageError(error.data.message)
                : setMessageError(GENERIC_ERROR_MESSAGE_HTML);
            }
          });
      }
    } else {
      const requestEmail = $("#email").val().trim();
      const requestPassword = $("#password").val();
      const code = urlParams.get("code") === "NA" ? "" : urlParams.get("code").trim();
      const requestOptions = {
        email: requestEmail,
        password: requestPassword,
        confirmPassword: requestPassword,
        code: code,
      };
      let url = "/Auth/password/noauth";
      if (!url.includes("undefined")) {
        Fetcher("PATCH", url, requestOptions)
          .then((response) => {
            displayModal = true;
            reroute();
          })
          .catch((error) => {
            pause = false;
            setDisabled(false);
            error && error.data && error.data.message
              ? setMessageError(error.data.message)
              : setMessageError(GENERIC_ERROR_MESSAGE_HTML);
          });
      }
    }
  };

  const rerouteToLogin = function () {
    window.location = "/login";
  };

  const rerouteToRegisterLogin = function () {
    window.location = "/login?temp=register";
  };

  const checkPassword = function () {
    let pass = document.getElementById("password").value;
    const re = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$");
    const ok = re.exec(pass);
    if (!ok && pass.length > 0) {
      setMessageError(
        "<p style='font-size: 12px;'>The password you entered does not meet the minimum password requirements. Please make sure that your password includes the following:</p>" +
          "<ul>" +
          "<li>At least 8 characters</li>" +
          "<li>At least one Uppercase letter (A-Z)</li>" +
          "<li>At least one Lowercase letter (a-z)</li>" +
          "<li>At least one Digit (0-9)</li>" +
          "</ul>"
      );
    }
  };

  const checkReroute = function () {
    if (!urlParams.get("code")) {
      if (register) {
        window.location.href = window.location.origin + "/login?temp=register";
      } else {
        window.location.href = window.location.origin + "/login?temp=passwordreset";
      }
    }
  };
  const checkFields = function () {
    if (!pause && !checkRequired("passwordForm")) {
      pause = true;
      validate();
    } else if (checkRequired("passwordForm")) {
      setDisabled(false);
    }
  };

  return email && password ? (
    <div>
      <Register
        email={email}
        password={password}
        firstName={firstName}
        lastName={lastName}
        code={urlParams.get("code").trim()}
        organizationName={organizationName}
        registerwithcode={registerwithcode ? true : false}
      />
    </div>
  ) : (
    <div>
      {checkReroute()}
      {registerwithcode && (
        <div
          className="registerTextM"
          style={{
            marginBottom: `12px`,
            color: `white`,
            fontWeight: `bold`,
            fontSize: `13px`,
            textAlign: `center`,
          }}
        >
          To get started, please enter your information below.{" "}
        </div>
      )}
      <form id="passwordForm">
        {urlParams.get("email") ? (
          <>
            <label htmlFor="email" className="text-input__label">
              Your Work Email Address:
            </label>
            <input
              onFocus={() => {
                checkPassword();
              }}
              onBlur={(e) => {
                if (e.target.value !== "") {
                  areEmailAndPasswordValid();
                }
              }}
              className="text-input"
              type="email"
              id="email"
              maxLength={254}
              onChange={(e) => {
                removeOutline(e.target);
              }}
              value={urlParams.get("email").trim()}
              readOnly
              required
            />
          </>
        ) : (
          <>
            <label htmlFor="email" className="text-input__label">
              Email:
            </label>
            <input
              onFocus={() => {
                checkPassword();
              }}
              onBlur={(e) => {
                if (e.target.value !== "") {
                  areEmailAndPasswordValid();
                }
              }}
              className="text-input"
              type="email"
              id="email"
              maxLength={254}
              defaultValue={urlParams.get("email") ? urlParams.get("email").trim() : ""}
              onChange={(e) => {
                removeOutline(e.target);
                setMessageError("");
              }}
              required
            />
          </>
        )}
        <label htmlFor="password" className="text-input__label">
          Please Create a New Password:
        </label>
        <input
          onFocus={() => {
            checkPassword();
          }}
          onBlur={() => {
            if (
              document.getElementById("confirmPassword").value !== "" &&
              document.getElementById("password").value !== ""
            ) {
              areEmailAndPasswordValid();
            }
            checkPassword();
          }}
          className="text-input"
          type="password"
          id="password"
          minLength={"8"}
          maxLength={"30"}
          onChange={(e) => {
            if (
              document.getElementById("confirmPassword").value === document.getElementById("password").value &&
              messageError === "<p>Password and Confirm Password must match.</p>"
            ) {
              setMessageError("");
            }
            const re = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$");
            const ok = re.exec(e.target.value);
            if (ok && messageError.includes("minimum password requirements")) {
              setMessageError("");
            }
            removeOutline(e.target);
          }}
          required
        />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css"></link>
        {
          <i
            onClick={() => {
              let x = document.getElementById("password");
              if (x.type === "text") {
                x.type = "password";
                x = document.getElementById("togglePassword");
                x.style.color = `#353f51`;
              } else {
                x.type = "text";
                x = document.getElementById("togglePassword");
                x.style.color = `black`;
              }
            }}
            className="far fa-eye eye"
            style={{
              display: `block`,
              marginTop: `-30px`,
              fontSize: `14px`,
              cursor: `pointer`,
              color: `#353f51`,
              marginBottom: `15px`,
            }}
            id="togglePassword"
          ></i>
        }
        <label htmlFor="confirmPassword" className="text-input__label">
          Please Confirm your New Password:
        </label>
        <input
          className="text-input"
          onFocus={() => {
            checkPassword();
          }}
          onBlur={() => {
            if (
              document.getElementById("confirmPassword").value !== "" &&
              document.getElementById("password").value !== ""
            ) {
              areEmailAndPasswordValid();
            }
            checkPassword();
          }}
          type="password"
          id="confirmPassword"
          minLength={"8"}
          maxLength={"30"}
          onChange={(e) => {
            if (
              document.getElementById("confirmPassword").value === document.getElementById("password").value &&
              messageError === "<p>Password and Confirm Password must match.</p>"
            ) {
              setMessageError("");
            }
            const re = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$");
            const ok = re.exec(e.target.value);
            if (ok && messageError.includes("minimum password requirements")) {
              setMessageError("");
            }
            removeOutline(e.target);
          }}
          required
        />
        {
          <i
            onClick={() => {
              let x = document.getElementById("confirmPassword");
              if (x.type === "text") {
                x.type = "password";
                x = document.getElementById("togglePassword2");
                x.style.color = `#353f51`;
              } else {
                x.type = "text";
                x = document.getElementById("togglePassword2");
                x.style.color = `black`;
              }
            }}
            className="far fa-eye eye"
            style={{
              display: `block`,
              marginTop: `-30px`,
              fontSize: `14px`,
              cursor: `pointer`,
              color: `#353f51`,
              marginBottom: `0px`,
            }}
            id="togglePassword2"
          ></i>
        }
        <br />
        {messageError ? (
          <p className="highContrastErrorMessage" role="alert" dangerouslySetInnerHTML={{ __html: messageError }} />
        ) : (
          <>
            <div style={{ float: `right` }}>
              {disable ? (
                <ArbButton size="small">{register ? "Next" : "Submit"}</ArbButton>
              ) : (
                <ArbButton type="submit" size="small" onClick={clickHandler}>
                  {register ? "Next" : "Submit"}
                </ArbButton>
              )}
            </div>
            <div
              style={{
                position: `absolute`,
                bottom: `25px`,
              }}
            >
              <a target="_blank" href="https://arbinger.com/faq" rel="noreferrer" className="loginLink">
                Portal Help
              </a>
            </div>
          </>
        )}
      </form>
      <Modal
        className="termsModal"
        size="md"
        style={{ border: `none`, zIndex: "999999" }}
        show={showConfirmModal}
        onHide={rerouteToLogin}
        centered
        scrollable={true}
      >
        <Modal.Header
          className="bold"
          style={{
            backgroundImage: `url(/Images/termsHeader.png)`,
            backgroundSize: `cover`,
            height: `45px`,
            color: `white`,
            border: `none`,
          }}
          closeButton={false}
        >
          <Modal.Title className="loginTermsOfServiceHeader" style={{ fontSize: `20px` }}>
            Password Confirmation
          </Modal.Title>
          <CloseModalButton onClick={rerouteToLogin} />
        </Modal.Header>
        <Modal.Body style={{ padding: `45px`, color: `#888888` }} className="loginModalBody">
          <p className="paragraph">You have successfully reset your password click ok to login with new password.</p>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <ArbButton size="small" onClick={rerouteToLogin}>
              Ok
            </ArbButton>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="termsModal"
        size="md"
        style={{ border: `none`, zIndex: "999999" }}
        show={showErrorModal}
        onHide={rerouteToRegisterLogin}
        centered
        scrollable={true}
      >
        <Modal.Header
          className="bold"
          style={{
            backgroundImage: `url(/Images/termsHeader.png)`,
            backgroundSize: `cover`,
            height: `45px`,
            color: `white`,
            border: `none`,
          }}
          closeButton={false}
        >
          <Modal.Title className="loginTermsOfServiceHeader" style={{ fontSize: `20px` }}>
            Invalid Email
          </Modal.Title>
          <CloseModalButton onClick={rerouteToRegisterLogin} />
        </Modal.Header>
        <Modal.Body style={{ padding: `45px`, color: `#888888` }} className="loginModalBody">
          <p>Email does not exist press ok to reenter registration email and code.</p>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <ArbButton size="small" onClick={rerouteToRegisterLogin}>
              Ok
            </ArbButton>
          </div>
        </Modal.Body>
      </Modal>
      <ArbModal title="You have already Registered" isVisible={showRerouteToLoginPopup}>
        <p className="paragraph">
          You have already registered this account, please{" "}
          <a href={`/login?email=${urlParams.get("email") ? urlParams.get("email").trim() : ""}`} className="loginLink">
            click here
          </a>{" "}
          to be routed back to the login page.
        </p>
      </ArbModal>
    </div>
  );
}

let responseData = "";
var pause = false;
let displayModal = false;
