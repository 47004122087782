import { DateTime } from "luxon";
import { getCookie } from "./Cookies.js";
import { parseJwt } from "./JwtHelper.js";

export function isAuthenticated() {
  const cookie = getCookie("token");
  if (!cookie) {
    return false;
  }

  const user = parseJwt(cookie);
  if (!user.Email || user.IsEmailVerifed === "False") {
    return false;
  }

  return true;
}

export function doesUserHaveAnAllowedRole(allowedRoles) {
  try {
    const token = getCookie("token");
    if (!token) return false;

    const user = parseJwt(token);
    if (!allowedRoles) {
      return true;
    }

    if (user.hasOwnProperty("role")) {
      if (user.role.indexOf("ArbingerAdmin") !== -1) return true;

      let doesUserHaveNeccessaryRole = false;

      allowedRoles.forEach((y) => {
        if (user.role.indexOf(y) !== -1) {
          doesUserHaveNeccessaryRole = true;
        }
      });

      return doesUserHaveNeccessaryRole;
    }

    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export function doesUserHavePermission(allowArbingerEmployees, items, permissionsAllowed) {
  try {
    const token = getCookie("token");
    if (!token) return false;

    const user = parseJwt(token);
    if (!items) {
      return true;
    }
    if (user.hasOwnProperty("IsArbingerEmployee") && user.IsArbingerEmployee !== "False" && allowArbingerEmployees) {
      return true;
    }
    let doesUserHaveAnAllowedPermission = false;
    items.forEach((x) => {
      if (user.hasOwnProperty(x)) {
        if (!permissionsAllowed) {
          doesUserHaveAnAllowedPermission = true;
        } else {
          permissionsAllowed.forEach((y) => {
            if (user[x].toLowerCase().includes(y.toLowerCase())) {
              doesUserHaveAnAllowedPermission = true;
            }
          });
        }
      }
    });

    return doesUserHaveAnAllowedPermission;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export function checkConsume(allowArbingerEmployees, items, permissionsAllowed) {
  try {
    const token = getCookie("token");
    if (!token) return false;
    const user = parseJwt(token);
    if (!items) {
      return false;
    }
    if (user.hasOwnProperty("IsArbingerEmployee") && user.IsArbingerEmployee !== "False" && allowArbingerEmployees) {
      return false;
    }
    let consume = false;
    items.forEach((x) => {
      if (user.hasOwnProperty(x) && permissionsAllowed) {
        permissionsAllowed.forEach((y) => {
          if (user[x].includes(y.toLowerCase()) && user[x].includes("/") === false) {
            consume = true;
          }
        });
      }
    });
    return consume;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export function checkAccessDate(items) {
  try {
    const token = getCookie("token");
    if (!token) return "";

    const user = parseJwt(token);
    if (!items) {
      return "";
    }
    if (user.hasOwnProperty("role") && user.role.indexOf("ArbingerAdmin") !== -1) return "";
    let accessDate = "";
    items.forEach((x) => {
      if (user.hasOwnProperty(x)) {
        if (user[x].includes("/")) {
          accessDate = user[x].split("|")[1];
        }
      }
    });

    const parsedAccessDate = DateTime.fromFormat(accessDate, "MM/dd/yyyy");
    const currDate = DateTime.now();
    if (currDate >= parsedAccessDate) {
      return "";
    }

    return accessDate;
  } catch (_error) {
    return "";
  }
}
