/* eslint-disable react/react-in-jsx-scope */
import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import Forbidden403 from "../Pages/403";
import { isAuthenticated, doesUserHavePermission } from "../Utils/isAuthenticated.js";

export function AuthRoute({ children, allowedRoles = null, ...rest }) {
  const [currentPath] = useState(window.location.pathname);
  const [params] = useState(window.location.search);

  return (
    <Route
      {...rest}
      render={() => {
        return isAuthenticated() ? (
          doesUserHavePermission(allowedRoles) ? (
            children
          ) : (
            <Forbidden403 />
          )
        ) : (
          <Redirect to={"/login?redirect=" + currentPath + params} />
        );
      }}
    />
  );
}
