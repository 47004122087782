import React, { useEffect } from "react";

export default function RedirectExternal({ to, tab, combined, lastRoute }) {
  useEffect(() => {
    if (combined) {
      tab
        ? window.open(process.env.REACT_APP_WORKBOOK_BASE_URL + "?config=" + combined + "&load=" + lastRoute, "_blank")
        : window.location.replace(
            process.env.REACT_APP_WORKBOOK_BASE_URL + "?config=" + combined + "&load=" + lastRoute
          );
      if (tab) {
        window.history.back();
      }
    } else {
      tab ? window.open(to, "_blank") : window.location.replace(to);
      if (tab) {
        window.history.back();
      }
    }
  });
  return <></>;
}
