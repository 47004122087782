import $ from "jquery";

export function checkRequired(formName) {
  const inputs = $("#" + formName + " input");
  let missing = false;
  for (let i = 0; i < inputs.length; i++) {
    if (inputs[i].value === "" && inputs[i].required) {
      missing = true;
      inputs[i].classList.add("inputFields");
    }
  }
  return missing;
}

export function removeOutline(element) {
  element.classList.remove("inputFields");
}

export function getFullLicenseTypeName(name) {
  let newName = "";
  if (name.includes("p")) {
    newName = newName + "Participant, ";
  }
  if (name.includes("P")) {
    newName = newName + "Participant (c), ";
  }
  if (name.includes("f")) {
    newName = newName + "Facilitator, ";
  }
  if (name.includes("F")) {
    newName = newName + "Facilitator (c), ";
  }
  if (name.includes("d")) {
    newName = newName + "Discussion Leader, ";
  }
  if (name.includes("D")) {
    newName = newName + "Discussion Leader (c), ";
  }
  if (name.includes("i") || name.includes("I")) {
    newName = newName + "International, ";
  }
  if (name.includes("o") || name.includes("O")) {
    newName = newName + "Grandfathered, ";
  }
  if (newName.length > 1) {
    newName = newName.slice(0, -2);
  }
  return newName;
}

export function determineBackgroundColor(columnData) {
  const greenHex = "#027948";
  const yellowHex = "#F5F53C";
  const invitedHex = "#2F6DA9";
  const arbBlueHex = "#009FDC";
  const purpleHex = "#FF22FF";
  const revokedHex = "#C11574";
  const redHex = "#EE0000";
  const declinedHex = "#B32318";

  if (typeof columnData === "boolean") {
    if (columnData) {
      return arbBlueHex;
    } else {
      return redHex;
    }
  }

  if (columnData) {
    if (columnData.toLowerCase() === "invited") return invitedHex;
    if (columnData.toLowerCase() === "declined") return declinedHex;
    if (columnData.toLowerCase() === "revoked") return revokedHex;
    if (columnData.toLowerCase() === "in progress") return purpleHex;
    if (columnData.toLowerCase() === "accepted") return greenHex;
    if (columnData.toLowerCase() === "completed") return arbBlueHex;
    if (columnData.toLowerCase() === "assigned") return yellowHex;
    if (columnData.toLowerCase() === "not licensed") return redHex;
    if (columnData.toLowerCase().includes("%")) return purpleHex;
  }

  return "";
}

export function getPrintWorkbookInfo(courseName, org) {
  let abbreviatedCourseName;
  switch (courseName) {
    case "Developing and Implementing an Outward Mindset":
      abbreviatedCourseName = "DIOMDIGITAL";
      break;
    case "Outward Leadership":
      abbreviatedCourseName = "OL";
      break;
    case "Outward Performance":
      abbreviatedCourseName = "OP";
      break;
    case "Outward Inclusion":
      abbreviatedCourseName = "OI";
      break;
    default:
      abbreviatedCourseName = null;
      break;
  }
  if (!abbreviatedCourseName) {
    return;
  }

  let workbookName;
  // eslint-disable-next-line default-case
  switch (abbreviatedCourseName.toUpperCase()) {
    case "DIOMDIGITAL":
    case "DIOM":
      workbookName = "DIOM_";
      break;
    case "OI":
      workbookName = "Outward_Inclusion_";
      break;
    case "OL":
      workbookName = "Outward_Leadership_";
      break;
    case "OP":
      workbookName = "Outward_Performance_";
      break;
    default:
      workbookName = "";
      break;
  }

  return {
    workbookName: workbookName,
    workbook: abbreviatedCourseName,
    organizationId: org,
    emails: [],
  };
}
