import React from "react";

interface IProps {
  onClick: any;
  useWhiteButton?: boolean;
}

export default function CloseModalButton({ onClick, useWhiteButton = false }: IProps) {
  return (
    <button
      tabIndex={0}
      style={{
        marginTop: `-4px`,
        marginRight: `0px`,
        cursor: "pointer",
        height: `20px`,
        width: `20px`,
        backgroundColor: `transparent`,
        position: "relative",
      }}
      className={useWhiteButton ? "focusable" : "focusableBlack"}
      onClick={onClick}
      aria-label="Close Dialog"
    >
      <img
        style={{
          position: "absolute",
          height: "20px",
          top: "0px",
          left: "0px",
          filter: useWhiteButton ? "invert(1)" : "invert(0)",
        }}
        src={window.location.origin + "/Images/closeTerms.png"}
        alt=""
      />
    </button>
  );
}
