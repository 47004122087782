import React from "react";

export default function LoginLayout({ children, showRegister }) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const temp = urlParams.get("temp");
  return (
    <>
      <div
        style={{
          backgroundImage: "url(/Images/loginBackground.png)",
          backgroundSize: `cover`,
        }}
      >
        <div
          style={{
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
            minHeight: `100vh`,
            minWidth: `100vw`,
          }}
        >
          <div
            style={{
              display: `flex`,
              flexDirection: `column`,
              alignItems: `start`,
            }}
          >
            <div
              style={{
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
              }}
            >
              <img
                className="mainImage LoginBoxShadowRight"
                src={window.location.origin + "/Images/loginImage.jpg"}
                alt="mosaic background"
              />
              <div className="rightCol LoginBoxShadowLeft loginFooter" style={{ position: `relative` }}>
                {showRegister && !temp && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      // className="sectionMargins"
                      style={{
                        position: `absolute`,
                        bottom: `25px`,
                        right: `0px`,
                        marginRight: "105px",
                        color: "#999",
                      }}
                    >
                      Don't have an account?{" "}
                      <a tabIndex={0} href="/register?code=NA" className="loginLink">
                        Register
                      </a>
                    </div>
                  </div>
                )}
                <div className="logo row justify-content-center ">
                  <img src={window.location.origin + "/Images/logo.png"} alt="Arbinger Logo" className="logoSize" />
                </div>
                <div className="sectionMargins login" style={{ fontSize: `16px` }}>
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
