import React from "react";

export default function Loading(props) {
  if (props.isLoading) {
    if (props.timedOut) {
      return (
        <div
          className="loginBackground"
          style={{
            backgroundImage: "url(/Images/blurredScreen.jpg)",
            display: `flex`,
            width: `100vw`,
          }}
        >
          <div
            style={{
              position: `absolute`,
              color: `white`,
              top: `50%`,
              right: `45%`,
              fontSize: `50px`,
            }}
          >
            Error: Loader Timed Out!
          </div>
        </div>
      );
    } else if (props.pastDelay) {
      return (
        <div
          className="loginBackground"
          style={{
            backgroundImage: "url(/Images/blurredScreen.jpg)",
            display: `flex`,
            width: `100vw`,
          }}
        >
          <div
            style={{
              position: `absolute`,
              color: `white`,
              top: `50%`,
              right: `45%`,
              fontSize: `50px`,
            }}
          >
            Loading...
          </div>
        </div>
      );
    } else {
      return null;
    }
  } else if (props.error) {
    return (
      <div
        className="loginBackground"
        style={{
          backgroundImage: "url(/Images/blurredScreen.jpg)",
          display: `flex`,
          width: `100vw`,
        }}
      >
        <div
          style={{
            position: `absolute`,
            color: `white`,
            top: `50%`,
            right: `45%`,
            fontSize: `50px`,
          }}
        >
          Error: Component Failed to Load!
        </div>
      </div>
    );
  } else {
    return null;
  }
}
