import axios from "axios";
import { getCookie, setCookie } from "./Cookies.js";

const Axios = axios.create({
  timeout: process.env.REACT_APP_API_TIMEOUT_IN_MS ?? 180000,
});
Axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

Axios.defaults.withCredentials = true;
Axios.defaults.headers["Content-Type"] = "application/json";

Axios.interceptors.request.use(
  function (req) {
    const token = getCookie("token");
    if (!token) {
      req.headers.authorization = "";
      return req;
    }
    req.headers.Authorization = "Bearer " + token;
    return req;
  },
  function (error) {
    Promise.reject(error);
  }
);

Axios.interceptors.response.use(null, function (error) {
  const daysTillExpiration = 7;
  const originalRequest = error.config;
  if (error.message && error.message === "Network Error") {
    return Promise.reject("Network Error");
  }

  if (error && error.response && error.response.status === 401) {
    if (error.response.data.errorCode === "INVALIDREFRESHTOKEN" || error.response.data.errorCode === "NOTOKEN") {
      setCookie("token", "", -1);
      const currentPath = window.location.pathname;
      window.location.href = "/login?redirect=" + currentPath;
    }
    if (error.response.data.errorCode === "JWTEXPIRED") {
      return Axios({
        method: "POST",
        url: "/auth/refreshtoken",
      })
        .then((resp) => {
          setCookie("token", resp.data.token, daysTillExpiration);
          Axios.defaults.headers.common["Authorization"] = "Bearer " + resp.data.token;
          return Axios(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }
    if (error.response.data.errorCode === "VERIFYEMAIL") {
      window.location.href = `/emailverification?email=${error.response.data.email}?reroute=true`;
    }
  }

  if (error.code === "ECONNABORTED") return Promise.reject({ data: { message: "Request timed out" } });
  return Promise.reject(error.response ?? error);
});

function Fetcher(method, url, data = null, blob = false) {
  return Axios.request({
    method,
    url,
    data,
    responseType: blob ? "blob" : "json",
  });
}

const getFetcher = (url) => Axios.get(url).then((res) => res.data);

export { getFetcher, Fetcher, Axios };
